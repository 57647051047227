import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Loading } from './components/Loading';

const AuditLogsPage = lazy(() => import('./pages/AuditLogsPage/AuditLogsPage'));

const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/" component={AuditLogsPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
