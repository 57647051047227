import MessageMap from '../i18n';

const idID: MessageMap = {
  test: {
    translate: 'Terjemahkan',
    missing: 'Bahasa Indonesia terpilih',
  },
  searchComponent: {
    searchTitle: 'Buat catatan aktivitas',
    description: 'Pilih aplikasi dan periode hingga 31 hari.',
    selectApplication: 'Pilih aplikasi',
    startDateAndTime: 'Tanggal dan waktu mulai',
    endDateAndTime: 'Tanggal dan waktu berakhir',
    filters: 'Filter',
    selectOperation: 'Pilih operasi',
    optional: '(opsional)',
    selectEntity: 'Pilih entitas',
    selectCountry: 'Pilih negara',
    entityIdPlaceholder: 'Masukkan ID Entitas',
    userIdPlaceholder: 'Masukkan email dan ID pengguna',
    clearFilters: 'Hapus kolom',
    showLogs: 'Tampilkan catatan aktivitas',
    requiredField: 'Kolom wajib diisi.',
    selectPeriod: 'Pilih periode hingga 31 hari.',
    invalidStartDate: 'Tanggal Awal harus sebelum Tanggal Akhir.',
  },
  activityComponent: {
    activityTitle: 'Catatan aktivitas',
    emptyMessage: 'Catatan aktivitas akan muncul di sini.',
    filteredBy: 'Difilter berdasarkan',
    clearFilters: 'Hapus filter',
    showResults: 'Menampilkan {length} hasil',
    exportCsv: 'Ekspor CSV',
    errorCsvDownload: 'Tidak dapat mengekspor file CSV. Coba lagi.',
    successCsvDownload: 'File CSV berhasil diekspor.',
  },
  logDetailsComponent: {
    details: 'Detail catatan',
    dateAndTime: 'Tanggal dan waktu',
    copyJson: 'Salin JSON',
    metadata: 'Metadata',
  },
  common: {
    application: 'Aplikasi',
    operation: 'Operasi',
    entity: 'Entitas',
    country: 'Negara',
    entityId: 'ID Entitas',
    userId: 'ID Pengguna',
    errorToast: 'Tidak dapat membuat catatn aktivitas. Coba lagi.',
    successToast: 'Catatan aktivitas berhasil dibuat.',
    startDate: 'Tanggal mulai',
    startTime: 'Waktu mulai',
    endDate: 'Tanggal berakhir',
    endTime: 'Waktu berakhir',
    table: {
      itemsPerPage: 'Baris per halaman: {option}',
      quantityIndicator: 'dari',
      dateAndTime: 'Tanggal dan waktu (UTC)',
      details: 'Detail',
      show: 'Tampilkan',
      emptyMessage:
        'Tidak ada informasi untuk ditampilkan. Silakan perbaiki pencarian Anda.',
    },
  },
};

export default idID;
