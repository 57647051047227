import MessageMap from '../i18n';

const deDE: MessageMap = {
  test: {
    translate: 'Übersetzen',
    missing: 'Deutsch wurde ausgewählt',
  },
  searchComponent: {
    searchTitle: 'Aktivitätsprotokolle generieren',
    description:
      'Wählen Sie eine Anwendung und einen Zeitraum von bis zu 31 Tagen aus.',
    selectApplication: 'Anwendung auswählen',
    startDateAndTime: 'Startdatum und -uhrzeit',
    endDateAndTime: 'Enddatum und -uhrzeit',
    filters: 'Filter',
    selectOperation: 'Einen Vorgang auswählen',
    optional: '(optional)',
    selectEntity: 'Eine Entität auswählen',
    selectCountry: 'Ein Land auswählen',
    entityIdPlaceholder: 'Eine Entitäts-ID eingeben',
    userIdPlaceholder: 'Eine Benutzer-E-Mail oder Benutzer-ID eingeben',
    clearFilters: 'Felder löschen',
    showLogs: 'Aktivitätsprotokolle anzeigen',
    requiredField: 'Pflichtfeld.',
    selectPeriod: 'Wählen Sie einen Zeitraum von bis zu 31 Tagen aus.',
    invalidStartDate: 'Das Anfangsdatum muss vor dem Enddatum liegen.',
  },
  activityComponent: {
    activityTitle: 'Aktivitätsprotokolle',
    emptyMessage: 'Die Aktivitätsprotokolle werden hier angezeigt.',
    filteredBy: 'Gefiltert nach',
    clearFilters: 'Filter löschen',
    showResults: 'Es werden {length} Ergebnisse angezeigt',
    exportCsv: 'CSV exportieren',
    errorCsvDownload:
      'CSV-Datei kann nicht exportiert werden. Versuchen Sie es erneut.',
    successCsvDownload: 'Die CSV-Datei wurde erfolgreich exportiert.',
  },
  logDetailsComponent: {
    details: 'Protokolldetails',
    dateAndTime: 'Datum und Uhrzeit',
    copyJson: 'JSON kopieren',
    metadata: 'Metadaten',
  },
  common: {
    application: 'Anwendung',
    operation: 'Vorgang',
    entity: 'Entität',
    country: 'Land',
    entityId: 'Entitäts-ID',
    userId: 'Benutzer-ID',
    errorToast:
      'Aktivitätsprotokolle können nicht generiert werden. Versuchen Sie es erneut.',
    successToast: 'Die Aktivitätsprotokolle wurden erfolgreich generiert.',
    startDate: 'Startdatum',
    startTime: 'Startzeit',
    endDate: 'Enddatum',
    endTime: 'Endzeit',
    table: {
      itemsPerPage: 'Zeilen pro Seite: {option}',
      quantityIndicator: 'von',
      dateAndTime: 'Datum und Uhrzeit (UTC)',
      details: 'Details',
      show: 'Anzeigen',
      emptyMessage:
        'Es gibt keine Informationen, die angezeigt werden können. Bitte verfeinern Sie Ihre Suche.',
    },
  },
};

export default deDE;
