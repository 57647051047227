import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  searchComponent: {
    searchTitle: 'Generate activity logs',
    description: 'Select an application and a period of up to 31 days.',
    selectApplication: 'Select an application',
    startDateAndTime: 'Start date and time',
    endDateAndTime: 'End date and time',
    filters: 'Filters',
    selectOperation: 'Select an operation',
    optional: '(optional)',
    selectEntity: 'Select an entity',
    selectCountry: 'Select a country',
    entityIdPlaceholder: 'Enter an entity ID',
    userIdPlaceholder: 'Enter an user email or ID',
    clearFilters: 'Clear fields',
    showLogs: 'Show activity logs',
    requiredField: 'Required field.',
    selectPeriod: 'Select a period of up to 31 days.',
    invalidStartDate: 'Das Anfangsdatum muss vor dem Enddatum liegen.',
  },
  activityComponent: {
    activityTitle: 'Activity logs',
    emptyMessage: 'The activity logs will appear here.',
    filteredBy: 'Filtered by',
    clearFilters: 'Clear filters',
    showResults: 'Showing {length} results',
    exportCsv: 'Export CSV',
    errorCsvDownload: 'Unable to export CSV file. Try again.',
    successCsvDownload: 'CSV file successfully exported.',
  },
  logDetailsComponent: {
    details: 'Log details',
    dateAndTime: 'Date and time',
    copyJson: 'Copy JSON',
    metadata: 'Metadata',
  },
  common: {
    application: 'Application',
    operation: 'Operation',
    entity: 'Entity',
    country: 'Country',
    entityId: 'Entity ID',
    userId: 'User ID',
    errorToast: 'Unable to generate activity logs. Try again.',
    successToast: 'Activity logs successfully generated.',
    startDate: 'Start date',
    startTime: 'Start time',
    endDate: 'End date',
    endTime: 'End time',
    table: {
      itemsPerPage: 'Lines per page: {option}',
      quantityIndicator: 'of',
      dateAndTime: 'Date and time (UTC)',
      details: 'Details',
      show: 'Show',
      emptyMessage:
        "There's no information to show. Please refine your search.",
    },
  },
};

export default enUS;
