import MessageMap from '../i18n';

const es419: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Idioma español seleccionado',
  },
  searchComponent: {
    searchTitle: 'Generar registros de actividades',
    description: 'Seleccione una aplicación y un período de hasta 31 días.',
    selectApplication: 'Seleccionar una aplicación',
    startDateAndTime: 'Fecha y hora de inicio',
    endDateAndTime: 'Fecha y hora de finalización',
    filters: 'Filtros',
    selectOperation: 'Seleccione una operación',
    optional: '(opcional)',
    selectEntity: 'Seleccione una entidad',
    selectCountry: 'Seleccione un país',
    entityIdPlaceholder: 'Ingrese un código de entidad',
    userIdPlaceholder: 'Ingrese un correo electrónico o una código de usuario',
    clearFilters: 'Borrar campos',
    showLogs: 'Mostrar registros de actividades',
    requiredField: 'Campo obligatorio.',
    selectPeriod: 'Seleccione un período de hasta 31 días.',
    invalidStartDate: 'La fecha inicial debe ser anterior a la fecha final.',
  },
  activityComponent: {
    activityTitle: 'Registros de actividades',
    emptyMessage: 'Los registros de actividad aparecerán aquí.',
    filteredBy: 'Filtrado por',
    clearFilters: 'Borrar filtros',
    showResults: 'Mostrando {length} resultados',
    exportCsv: 'Exportar CSV',
    errorCsvDownload:
      'No se puede exportar el archivo CSV. Inténtelo de nuevo.',
    successCsvDownload: 'El archivo CSV se exportó correctamente.',
  },
  logDetailsComponent: {
    details: 'Detalles del registro',
    dateAndTime: 'Fecha y hora',
    copyJson: 'Copiar JSON',
    metadata: 'Metadatos',
  },
  common: {
    application: 'Aplicación',
    operation: 'Operación',
    entity: 'Entidad',
    country: 'País',
    entityId: 'Código de entidad',
    userId: 'Código de usuario',
    errorToast:
      'No se pueden generar registros de actividad. Inténtelo de nuevo.',
    successToast: 'Los registros de actividad se generaron correctamente.',
    startDate: 'Fecha de inicio',
    startTime: 'Hora de inicio',
    endDate: 'Fecha de finalización',
    endTime: 'Hora de finalización',
    table: {
      itemsPerPage: 'Líneas por página: {option}',
      quantityIndicator: 'de',
      dateAndTime: 'Fecha y hora (UTC)',
      details: 'Detalles',
      show: 'Mostrar',
      emptyMessage:
        'No hay información para mostrar. Por favor, refine su búsqueda.',
    },
  },
};

export default es419;
