import MessageMap from '../i18n';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Língua portuguesa selecionada',
  },
  searchComponent: {
    searchTitle: 'Gerar registros de atividade',
    description: 'Selecione um aplicativo e um período de até 31 dias.',
    selectApplication: 'Selecionar um aplicativo',
    startDateAndTime: 'Data e hora de início',
    endDateAndTime: 'Data e hora de término',
    filters: 'Filtros',
    selectOperation: 'Selecione uma operação',
    optional: '(opcional)',
    selectEntity: 'Selecione uma entidade',
    selectCountry: 'Selecione um país',
    entityIdPlaceholder: 'Insira uma ID de entidade',
    userIdPlaceholder: 'Insira um e-mail ou ID de usuário',
    clearFilters: 'Limpar campos',
    showLogs: 'Mostrar registros de atividade',
    requiredField: 'Campo obrigatório.',
    selectPeriod: 'Selecione um período de até 31 dias.',
    invalidStartDate: 'A data inicial deve ser anterior à data final.',
  },
  activityComponent: {
    activityTitle: 'Registros de atividade',
    emptyMessage: 'Os registros de atividade aparecerão aqui.',
    filteredBy: 'Filtrado por',
    clearFilters: 'Limpar filtros',
    showResults: 'Mostrando {length} resultados',
    exportCsv: 'Exportar CSV',
    errorCsvDownload:
      'Não foi possível exportar o arquivo CSV. Tente novamente.',
    successCsvDownload: 'Arquivo CSV exportado com sucesso.',
  },
  logDetailsComponent: {
    details: 'Detalhes do registro',
    dateAndTime: 'Data e hora',
    copyJson: 'Copiar JSON',
    metadata: 'Metadados',
  },
  common: {
    application: 'Aplicativo',
    operation: 'Operação',
    entity: 'Entidade',
    country: 'País',
    entityId: 'ID da entidade',
    userId: 'ID do usuário',
    errorToast:
      'Não foi possível gerar registros de atividade. Tente novamente.',
    successToast: 'Registros de atividade gerados com sucesso.',
    startDate: 'Data de início',
    startTime: 'Hora de início',
    endDate: 'Data de término',
    endTime: 'Hora de término',
    table: {
      itemsPerPage: 'Linhas por página: {option}',
      quantityIndicator: 'de',
      dateAndTime: 'Data e hora (UTC)',
      details: 'Detalhes',
      show: 'Mostrar',
      emptyMessage:
        'Não há informações para mostrar. Refine sua pesquisa, por favor.',
    },
  },
};

export default ptBR;
