import React from 'react';

export type AppTranslations = {
  'en-US': string;
  'es-419': string;
  'pt-BR': string;
};

export type AppsConfiguration = {
  id: string;
  appName: AppTranslations;
  appDescription: AppTranslations;
  appScopePrefix: string;
  baseRoute: string;
  homeRoute: string;
  hidden: boolean;
};

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
  appsConfiguration: AppsConfiguration[];
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  appsConfiguration: [],
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}) => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
