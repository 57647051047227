import { LoadingBuzz } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Loading = (): JSX.Element => {
  return (
    <Container>
      <LoadingBuzz size="xlarge" />
    </Container>
  );
};

export default Loading;

const Container = styled('div', {
  width: '100%',
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 0 0 0',
});
