import { EnvConfig, EnvProvider } from './components';
import { IntlProvider } from './i18n';
import Routes from './Routes';

export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <Routes />
      </IntlProvider>
    </EnvProvider>
  );
}
