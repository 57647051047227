import MessageMap from '../i18n';

const koKR: MessageMap = {
  test: {
    translate: '번역',
    missing: '선택한 한국어',
  },
  searchComponent: {
    searchTitle: '활동 로그 생성',
    description: '애플리케이션 및 최대 31일의 기간을 선택합니다.',
    selectApplication: '애플리케이션 선택',
    startDateAndTime: '시작 날짜 및 시간',
    endDateAndTime: '종료 날짜 및 시간',
    filters: '필터',
    selectOperation: '작업 선택',
    optional: '(선택 사항)',
    selectEntity: '개체 선택',
    selectCountry: '국가 선택',
    entityIdPlaceholder: '개체 ID 입력',
    userIdPlaceholder: '사용자 이메일 또는 ID 입력',
    clearFilters: '필드 지우기',
    showLogs: '활동 로그 표시',
    requiredField: '필수 필드입니다.',
    selectPeriod: '최대 31일의 기간을 선택합니다.',
    invalidStartDate: '시작 날짜는 종료 날짜보다 이전이어야합니다.',
  },
  activityComponent: {
    activityTitle: '활동 로그',
    emptyMessage: '활동 로그가 여기에 표시됩니다.',
    filteredBy: '필터링 기준',
    clearFilters: '필터 지우기',
    showResults: '{length}개의 결과 표시 중',
    exportCsv: 'CSV 내보내기',
    errorCsvDownload: 'CSV 파일을 내보내기할 수 없습니다. 다시 시도하십시오.',
    successCsvDownload: 'CSV 파일이 성공적으로 내보내기되었습니다.',
  },
  logDetailsComponent: {
    details: '로그 세부 정보',
    dateAndTime: '날짜 및 시간',
    copyJson: 'JSON 복사',
    metadata: '메타데이터',
  },
  common: {
    application: '애플리케이션',
    operation: '작업',
    entity: '개체',
    country: '국가',
    entityId: '개체 ID',
    userId: '사용자 ID',
    errorToast: '활동 로그를 생성할 수 없습니다. 다시 시도하십시오.',
    successToast: '활동 로그가 성공적으로 생성되었습니다.',
    startDate: '시작일',
    startTime: '시작 시간',
    endDate: '종료일',
    endTime: '종료 시간',
    table: {
      itemsPerPage: '페이지당 라인 수: {option}',
      quantityIndicator: '중',
      dateAndTime: '날짜 및 시간(UTC)',
      details: '세부 정보',
      show: '표시',
      emptyMessage: '표시할 정보가 없습니다. 검색어를 다시 입력해주세요.',
    },
  },
};

export default koKR;
